import React, { useState } from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import TextImageHero from '@components/common/TextImageHero'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import WriteForUsModal from '@components/pages/writeForUs/WriteForUsModal'
import getGlobalDocument from '@configs/getGlobalDocument'

const WriteForUsHeroSection = ({ intl }) => {
  const [open, setOpen] = useState(false)

  function onOpen(e) {
    e.preventDefault()
    setOpen(true)
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]
    documentStyle.style.overflow = 'hidden'
  }

  const handleClose = () => {
    setOpen(false)
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]
    documentStyle.style.overflow = 'auto'
  }

  return (
    <SectionWrapper hidePaddingBottom>
      <WriteForUsModal open={open} handleClose={handleClose} />
      <TextImageHero
        title="Write for IdeaBuddy"
        description={[
          'Are you a small business owner, entrepreneur, or a freelancer looking to get some writing exposure and share your experiences with a wider audience? Then, you’re in the right place.',
        ]}
        image={<StaticImage alt="hero" src="../../assets/images/writeForUs/hero.png" width={580} />}
        button={<ButtonLink buttonText="Apply Now" mdfontsize={'16px'} onClick={(e) => onOpen(e)} />}
      />
    </SectionWrapper>
  )
}

export default injectIntl(WriteForUsHeroSection)
