import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import ContactUsSection from '@sections/common/ContactUsSection'
import WriteForUsHeroSection from '@sections/write-for-us/WriteForHeroSection'
import WriteForUsContentSection from '@sections/write-for-us/WriteForUsContentSection'

const WriteForUs = ({ intl }) => {
  return (
    <Layout pageName="write-for-us">
      <WriteForUsHeroSection />
      <WriteForUsContentSection />
      <ContactUsSection
        title="Contact us"
        description="If you want to write for us, please take a look at our guidelines and terms of use, and if you are fine with it, please complete the form below."
        buttonText="Contact Us"
        writeForUsModal
      />
    </Layout>
  )
}

export default injectIntl(WriteForUs)
