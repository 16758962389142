import React from 'react'
import styled from 'styled-components'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'

const WriteForUsContentSection = () => {
  return (
    <SectionWrapper>
      <Wrapper>
        <h2>Guidelines</h2>
        <p>
          The aim of our blog is to educate, share experiences, and give that little push to the aspiring entrepreneurs
          to take the first step towards realizing their dreams.
        </p>
        <p>Here are the general guidelines:</p>
        <ul>
          <li>Ideal story length is 800-1200 words</li>
          <li>Your article shouldn’t be published anywhere else (including your own blog)</li>
          <li>Check the grammar before sending us the article for review</li>
          <li>
            All images included in the article must be your own or with all the necessary information about copyrights
          </li>
          <li>
            Any references included in the article should be relevant and provide additional information about the given
            topic
          </li>
          <li>
            We reserve the right to reject any article that doesn’t meet our guidelines or fit with our audience profile
          </li>
        </ul>
      </Wrapper>

      <Wrapper>
        <h2>Topics we cover</h2>
        <p>
          Our blog serves as a learning hub for budding entrepreneurs, startups or anyone who wants to start and run
          their own business.
        </p>
        <p>We cover topics related to:</p>

        <ul>
          <li>Startups (startup journey, advice for an early-stage startup)</li>
          <li>Business ideas (lists of inspirational ideas, or ideas for specific types of people)</li>
          <li>Business guides (steps required to start a specific business)</li>
          <li>Business models (explaining how different business model work)</li>
          <li>Business planning (everything related to it)</li>
          <li>Market analysis (how to analyze competitors, target customers, etc.)</li>
          <li>Small businesses tips</li>
          <li>Growth hacking techniques</li>
          <li>
            Before sending us your topic ideas, we recommend looking over our blog and checking whether we already wrote
            about the topics you’ve had in mind.
          </li>
        </ul>
      </Wrapper>

      <Wrapper>
        <h2 className="margin-bottom">Terms of use</h2>

        <p className="no-margin">We don’t pay for publishing your article</p>
        <p className="no-margin">We will remove articles that are not genuine or published somewhere else</p>
        <p className="no-margin">
          If an article is published on our website, it is then owned by us and you cannot re-publish it without our
          prior written approval
        </p>
      </Wrapper>
    </SectionWrapper>
  )
}

export default WriteForUsContentSection

const Wrapper = styled.div`
  margin-bottom: 40px;

  .no-margin {
    margin: 0;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  p {
    margin: 20px 0;
    font-size: 16px;
  }

  ul {
    padding: 0 30px 0;
  }
`
